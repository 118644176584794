_cruxUtils._cruxLocale = {
"crux.no.bc.message":"\u6c92\u6709\u53ef\u7528\u7684\u6982\u89c0\u3002\u6309\u4e00\u4e0b\u8a18\u9304\u4ee5\u6aa2\u8996\u66f4\u591a\u8a73\u7d30\u8cc7\u6599",
"crm.appointments.status.markcompleted1": "\u6a19\u70ba\u5df2\u5b8c\u6210",
"crm.mass.actions.all.selected": "<span class=\"dIB vaM\">\u5728\u9019\u500b\u8996\u5716\u4e2d\u6240\u6709\u7684</span><span class='mL3 mR3 crm-font-bold dIB vaM recordCountHt'>{0}</span> <span class=\"dIB vaM\">{1}\u88ab\u9078\u4e2d\u3002 </span>",
"crm.module.selectall": "\u5728\u9019\u500b\u8996\u5716\u4e2d\u9078\u64c7\u6240\u6709{0}",
"records": "\u8a18\u9304",
"crm.record.not.accessible": "\u8a18\u9304",
"crm.view.calls.recording.err.msg": "\u8a9e\u97f3\u8a18\u9304\u6c92\u6709\u53ef\u64ad\u653e\u7684\u97f3\u983bURL\u3002",
"crm.listview.selected.records": "<span class='mL3 crm-small-font-size crm-font-bold vaM dIB'>{0} </span><span class=\"mL3 seletcedRecords\">\u00A0{1}\u88ab\u9078\u4e2d\u3002 </span>",
"totalrecords": "\u5168\u90e8\u8a18\u9304",
"crm.listview.options.text.clip": "\u7e2e\u7565\u986f\u793a",
"crm.listview.options.text.wrap": "\u81ea\u52d5\u63db\u884c",
"crm.listview.options.reset.width": "\u91cd\u7f6e\u5217\u7684\u5927\u5c0f",
"crm.listview.options.manage": "\u7ba1\u7406\u5217",
"crm.listview.customview.locked": "\u5217\u4e0d\u80fd\u5b9a\u5236\uff0c\u56e0\u70ba\u8a72\u8996\u5716\u5df2\u88ab\u9396\u5b9a",
"crm.api.apidashboard.SortBy": "\u6392\u5e8f\u6309",
"crm.privacy.listview.consent.locked": "\u8a18\u9304\u5df2\u88ab\u9396\u5b9a\u8655\u7406",
"crm.label.reschedule.call": "\u91cd\u65b0\u5b89\u6392{0}",
"crm.button.edit": "\u7de8\u8f2f",
"Delete": "\u522a\u9664",
"crm.appointments.status.markcancel": "\u53d6\u6d88{0}",
"crm.appointments.status.markreschedule": "\u91cd\u65b0\u9810\u5b9a{0}",
"crm.label.cancel.call": "\u53d6\u6d88{0}",
"crm.label.mark.completed": "\u6a19\u8a18\u70ba\u5df2\u5b8c\u6210",
"crm.listview.customview.locked": "\u5217\u4e0d\u80fd\u5b9a\u5236\uff0c\u56e0\u70ba\u8a72\u8996\u5716\u5df2\u88ab\u9396\u5b9a",
"pf.deleted.state": "\u522a\u9664\u7684\u72c0\u614b",
"crm.project.integration.title": "Zoho Projects",
"crm.integ.sync.message": "\u8207{0}\u9032\u884c\u540c\u6b65",
"crm.schedules.prefix.in": "\u5728",
"crm.record.record.locked.other": "\u8a18\u9304\u5c0d\u5176\u4ed6\u89d2\u8272\u7684\u7528\u6236\u88ab\u9396\u5b9a\u3002",
"crm.record.lock.record.locked": "\u8a18\u9304\u88ab\u9396\u5b9a",
"crm.approvalProcess.label.waitingForApproval": "\u7b49\u5f85\u5be9\u6279",
"crm.reviewprocess.record.review.pending": "\u5f85\u5be9\u6838",
"crm.approvalProcess.label.waitingForFindAndMerge": "\u6b63\u5728\u8655\u7406\u91cd\u8907\u7684\u5408\u4f75\u3002",
"crm.privacy.listview.consent.locked": "\u8a18\u9304\u5df2\u88ab\u9396\u5b9a\u8655\u7406",
"crm.zia.vision.processing": "Zia\u6b63\u5728\u9a57\u8b49\u8a72\u5716\u7247\u3002",
"crm.zia.vision.rejected.msg": "\u7531\u65bc\u5716\u7247\u9a57\u8b49\u5931\u6557\uff0c\u8a18\u9304\u5df2\u88ab\u99c1\u56de\u3002",
"crm.zia.vision.record.failure.msg": "\u7531\u65bc\u5716\u7247\u9a57\u8b49\u5931\u6557\uff0c\u8a18\u9304\u6b63\u5728\u7b49\u5f85\u5be9\u6279\u3002",
"crm.alert.max.cvcols.reached": "\u5728\u4e00\u500b\u81ea\u8a02\u8996\u5716\u4e2d\u60a8\u6700\u591a\u53ef\u4ee5\u9078\u64c7{0}\u6b04\u4f4d",
"crm.label.small.tags": "\u6a19\u7c64",
"crm.label.edit.module": "\u7de8\u8f2f{0}",
"crm.social.integ.no.result": "\u5c1a\u672a\u627e\u5230\u7d50\u679c",
"custommodule.crmfield.fl.name": "{0} \u540d\u7a31",
"Appointment": "\u9810\u7d04",
"crm.button.create.call": "\u5275\u5efa\u901a\u8a71",
"crm.module.create": "\u5275\u5efa{0}",
"crm.customview.activty.badge": "\u6d3b\u52d5\u6a19\u8a18",
"crm.button.callnow": "\u7acb\u5373\u96fb\u8a71",
"crm.manualcalllist.complete.call": "\u8a18\u9304\u901a\u8a71",
"crm.label.schedule.call": "\u9810\u7d04\u901a\u8a71",
"crm.filter.label.replied":"\u5df2\u56de\u8986",
"bytes": "\u4f4d\u5143\u7d44",
"crm.field.label.user.lookup": "\u7528\u6236",
"crm.translation.refresh.cases": "\u7121\u6cd5\u5b8c\u6210\u60a8\u8981\u57f7\u884c\u7684\u64cd\u4f5c\uff0c\u8acb\u5237\u65b0\u9801\u9762\u5f8c\u518d\u6b21\u5617\u8a66\u3002",
"crm.project.sync.notes.info": "\u5c07\u6703\u55ae\u7368\u986f\u793a\u95dc\u806f\u5c08\u6848\u7684\u4efb\u52d9\u8a55\u8ad6",
"crux.note.content.limit.warning.msg":"\u50c5\u5141\u8a31\u5728\u9644\u8a3b\u5167\u5bb9\u4e2d\u5132\u5b58 {0} \u500b\u5b57\u5143",
"crux.note.formatting.options":"\u683c\u5f0f\u8a2d\u5b9a\u9078\u9805",
"crm.label.required": "\u5fc5\u586b",
"crux.condition.not.belongs.to":"\u4e0d\u5c6c\u65bc {0}",
"crm.project.sync.notes.info": "\u5c07\u6703\u55ae\u7368\u986f\u793a\u95dc\u806f\u5c08\u6848\u7684\u4efb\u52d9\u8a55\u8ad6",
"crux.note.this.note":"\u6b64\u5099\u8a3b",
"KB": "KB",
"MB": "MB",
"current.logged.in.user.role": "\u767b\u9304\u7528\u6236\u7684\u8077\u4f4d",
"Unit Price": "\u5b9a\u50f9",
"Qty in Stock": "\u5eab\u5b58\u6578\u91cf",
"crm.label.Product Code": "\u7522\u54c1\u4ee3\u78bc",
"TotalAfterDiscount": "\u6298\u6263\u5f8c\u5408\u8a08",
"crm.lookupfilter.entity.errormsg": "\u6240\u9078\u7684{0}\u4e0d\u7b26\u5408\u8a72\u67e5\u627e\u689d\u4ef6\u3002",
"crm.iamexception.maxlen": "\u60a8\u4e0d\u80fd\u8f38\u5165{0}\u500b\u4ee5\u4e0a\u7684 {1}\u5b57\u7b26",
"crm.record.locking.permission.denied": "\u7121\u6cd5\u57f7\u884c\u6b64\u64cd\u4f5c\uff0c\u56e0\u70ba\u8a18\u9304\u5df2\u88ab\u9396\u5b9a\u3002",
"List Price": "\u55ae\u50f9",
"crm.label.no.more.records": "\u6c92\u6709\u66f4\u591a\u8a18\u9304",
"crm.workflow.rule.created.dateAndTime":"{0} \u65bc {1} \u5728 {2}",
"crux.new.note.msg":"\u6b64\u5099\u8a3b\u95dc\u65bc\u4ec0\u9ebc\uff1f",
"crm.image.uploaded":"\u5df2\u4e0a\u50b3\u5f71\u50cf",
"crm.imageupload.drag.drop.here":"\u5728\u9019\u88cf\u62d6\u653e\u5f71\u50cf",
"crm.fileupload.drag.drop.here":"\u5728\u9019\u88cf\u62d6\u653e\u6a94\u6848",
"crm.fileupload.wait.msg":"\u5728\u4e0a\u50b3\u6240\u9078\u6a94\u6848\u524d\uff0c\u8acb\u7b49\u5f85",
"crm.fileupload.attach.fail":"\u9644\u52a0\u5931\u6557",
"crm.fileupload.support.format":"Zia \u57f9\u8a13\u7684\u652f\u63f4\u6a94\u6848\u683c\u5f0f (jpeg, jpg, png, pdf)",
"custmr.prtl.user.role": "\u9580\u6236\u7528\u6236",
"crm.subform.empty.alert.header":"\u7a7a\u5217\u7121\u6cd5\u5132\u5b58",
"crm.crud.lookup.module.inaccessible": "\u60a8\u6c92\u6709\u6b0a\u9650\u8a2a\u554f\u9019\u500b\u67e5\u8a62\u6a21\u7d44\u3002",
"crm.crud.lookup.inaccessible.record": "\u8a18\u9304\u4e0d\u5b58\u5728\u6216\u8005\u60a8\u4e0d\u80fd\u8a2a\u554f",
"crm.sf.permission.no.delete": "\u60a8\u6c92\u6709\u522a\u9664\u689d\u76ee\u7684\u6b0a\u9650\u3002",
"crm.sf.permission.no.create": "\u60a8\u6c92\u6709\u6dfb\u52a0\u689d\u76ee\u7684\u6b0a\u9650\u3002",
"crm.sf.permission.no.edit": "\u60a8\u6c92\u6709\u66f4\u65b0\u689d\u76ee\u7684\u6b0a\u9650\u3002",
"crm.custombutton.valid.weburl.check": "\u8acb\u8f38\u5165\u4e00\u4e9b\u503c\u3002",
"crm.label.subform.goto.top": "\u8f49\u5230\u5217\u8868\u9802\u90e8",
"crm.label.subform.row.show.all": "\u986f\u793a\u5168\u90e8({0})",
"cpq.pr.nodiscount": "\u6c92\u6709\u76f8\u95dc\u7684\u6298\u6263",
"crm.inventory.lineitem.no.pricebook.new": "\u6c92\u6709{0}\u88ab\u95dc\u806f\u3002",
"crm.label.subform.allowedlimit": "\u5df2\u9054\u5230\u5141\u8a31\u7684{0}\u884c\u7684\u9650\u5236",
"crm.reports.integ.okay": "\u78ba\u5b9a",
"crm.crud.subform.deleted.record": "\u66f4\u65b0\u7684\u5b50\u8868\u55ae\u7684\u884c\u5df2\u88ab\u522a\u9664\u3002\u8acb\u5237\u65b0\u9801\u9762\u4e26\u91cd\u8a66\u3002",
"crux.criteria.fieldlabel.valid.check":"\u8acb\u8f38\u5165\u6709\u6548\u7684\u6b04\u4f4d\u6a19\u7c64",
"crm.subform.delete.confirm.header":"刪除子表單列",
"crm.subform.delete.confirm.msg": "是否確定要刪除 {0} 的選定列？",
"crm.subform.delete.tooltip.msg" :"該列無法刪除。要求 {0} 至少錄入一列。",
"crm.orchestration.error.selectvalue": "\u8acb\u9078\u64c7\u4e00\u500b\u503c",
	"crm.attach.upload.image":"\u4e0a\u8f09\u5716\u7247",//no i18n
"crm.general.select.photo":"\u9078\u64c7\u7167\u7247",//no i18n
"crm.imageupload.wait.msg":"\u8acb\u7b49\u5f85\uff0c\u76f4\u5230\u6240\u9078\u7684\u5716\u7247\u4e0a\u8f09\u5b8c\u6210\u3002",//no i18n
"crm.image.filepicker.desc.draganddrop":"\u62d6\u653e\u5716\u7247\u3002",//no i18n
"crm.image.filepicker.desc.browse":"\u9ede\u64ca\u700f\u89bd\u5716\u7247...",//no i18n
"crm.attach.upload.userinfo":"\u7e3d\u5927\u5c0f\u9650\u5236\u70ba<span class",//no i18n
"crm.image.supported.formats":"\u652f\u6301\u7684\u683c\u5f0f\uff1aJPEG, PNG, GIF\u548cBMP\u3002",//no i18n
"crm.imageupload.allowed.field.length":"\u60a8\u6700\u591a\u53ea\u80fd\u4e0a\u8f09{0}\u5f35\u5716\u7247\u3002",//no i18n
"crm.general.crop.and.set":"\u88c1\u526a\u8207\u8a2d\u7f6e",//no i18n
"crm.attach.option.label":"\u9644\u52a0",//no i18n
"crm.image.crop.and.rotate":"\u88c1\u526a\u4e26\u65cb\u8f49",//no i18n
"crm.image.resolution":"\u5206\u8fa8\u7387",//no i18n
"crm.attachment.size":"\u5927\u5c0f",//no i18n
"crm.label.close":"\u95dc\u9589",//no i18n
"crm.label.previous":"\u4e0a\u4e00\u9801",//no i18n
"crm.label.next":"\u4e0b\u4e00\u9801",//no i18n
"crm.subform.record.create.maxrow.limit": "{1}\u4e2d\u6700\u591a\u5141\u8a31{0}\u689d\u8a18\u9304",
"crm.label.subform.addrows": "\u6dfb\u52a0\u884c",
"crm.tax.association.check": "\u8a72\u7522\u54c1\u6c92\u6709\u95dc\u806f\u7a05",
	"crm.gallery.inventory.template.discount": "\u6298\u6263",
	"crm.inventory.discount.scheme.range": "\u7bc4\u570d",
	"Description": "\u8aaa\u660e",
	"crm.inventory.lineitem.no.pricebook": "\u6c92\u6709\u95dc\u806f\u7684\u50f9\u683c\u8868\u3002",
	"crm.recordImage.previewimage":"\u9810\u89bd\u5716\u50cf",//no i18n
"crm.FileuploadField.addNewImage":"\u4e0a\u8f09\u5716\u7247",//no i18n
"crm.fileuploader.removefile":"\u522a\u9664",//no i18n
	"voc.gc.configure":"\u914d\u7f6e",//no i18n
"Edit":"\u7de8\u8f2f",//no i18n
	"crm.criteria.secondaryfield.valid.check" : "\u8acb\u63d0\u4f9b {0} \u7684\u76f8\u7b26\u503c",
	"crm.label.field":"\u6b04\u4f4d",//no i18n
"crm.label.value":"\u503c",//no i18n
	"crm.button.clone":"\u8907\u88fd",//no i18n
	"sentiment.criteria.wrongcriteria":"\u689d\u4ef6\u503c\u4e0d\u80fd\u8d85\u904e{0}",//no i18n
"crm.mb.field.common.splc":"\u4e0d\u5141\u8a31\u7279\u6b8a\u5b57\u5143\u3002\u8acb\u8f38\u5165\u6709\u6548\u503c\u3002",//no i18n
	"crm.view.attachment.download":"\u4e0b\u8f09",//no i18n
"crm.label.view":"\u67e5\u770b",//no i18n
	"crm.label.field.plural":"\u6b04\u4f4d",//no i18n
	"crm.label.in.minutes":"{0}\uff08\u5206\uff09",//no i18n
	"crm.security.roles.list":"\u8077\u4f4d\u5217\u8868",//no i18n
"crm.security.roles.lookup.info":"\u8acb\u5f9e\u4e0b\u9762\u7684\u5217\u8868\u4e2d\u9078\u64c7\u4e00\u500b\u8077\u4f4d\u3002",//no i18n
"crm.territory.addterritory":"\u6dfb\u52a0\u5340\u57df",//no i18n
"crm.title.edit.territory":"\u7de8\u8f2f\u5340\u57df",//no i18n
"crm.territory.title.assign.territories":"\u5206\u914d\u5340\u57df",//no i18n
	"crm.label.context.help":"\u5e6b\u52a9",//no i18n
	"crm.label.from":"\u5f9e",//no i18n
"crm.label.to":"\u5230",//no i18n
	"workflow.option.webhookFailure.fromDate":"\u958b\u59cb\u6642\u9593",//no i18n
"workflow.option.webhookFailure.toDate":"\u7d50\u675f\u6642\u9593",//no i18n
"crm.custom.field.less.than.equalto":"{0}\u61c9\u8a72\u5c0f\u65bc\u6216\u8005\u7b49\u65bc{1}",//no i18n
	"crm.template.listview.search.no.results":"\u6c92\u6709\u627e\u5230\u7d50\u679c",//No I18n
	"crm.label.tag.new":"\u65b0\u5efa\u6a19\u7c64",//No I18n
	"crm.label.enter.tag":"\u8f38\u5165\u6a19\u7c64",//No I18n
	"crux.comboBox.max.limit":"\u60a8\u7684\u9078\u64c7\u4e0d\u80fd\u8d85\u904e{0} {1}.",//NO I18n
	"Administrator":"\u7ba1\u7406\u54e1",//No I18n
	"Standard":"\u6a19\u6e96",//No I18n
	"crm.button.add":"\u6dfb\u52a0",//NO I18n
	"crm.wf.usage.date.criteria.error.msg":"\u958b\u59cb\u65e5\u671f\u4e0d\u80fd\u5927\u65bc\u7d50\u675f\u65e5\u671f\u3002",//no i18n
	"crm.label.users":"\u7528\u6236", //NO I18n
  "crm.workflow.alert.roles":"\u8077\u4f4d", //NO I18n
  "crm.security.groups":"\u7d44", //NO I18n
	"crm.label.available" : "\u53ef\u9078\u7684", //NO I18n
	"crm.label.assign.manually" : "\u5206\u914d", //NO I18n
	"crm.globalsearch.option.all": "\u5168\u90e8", //NO I18n
	"webform.status.Active":"\u555f\u52d5", //NO I18n
	"Inactive":"\u7981\u7528", //NO I18n
  "Confirmed":"\u5df2\u78ba\u8a8d", //NO I18n
  "crm.user.component.unconfirmed":"\u672a\u78ba\u8a8d\u7684",//no i18n
  "DeletedUser":"\u522a\u9664\u7684", //NO I18n
  "crm.feed.group.admin":"\u7ba1\u7406\u54e1", //NO I18n
  "crm.ln.lable.current":"\u7576\u524d", //NO I18n
	"crm.label.selected": "\u9078\u64c7\u7684",//NO I18n
	"crm.auditlog.user": "\u7528\u6236", //NO I18n
	"cob.role": "\u8077\u4f4d", //NO I18n
	"zoho.email": "\u90f5\u4ef6", //NO I18n
	"Profile": "\u89d2\u8272", //NO I18n
	"crm.security.group.users.empty": "No users found.", //NO I18n
	"crm.label.picklist.none": "--\u7121--", //NO I18n
	"crm.usrpop.non.selected" : "\u9078\u4e2d\u7684\u7528\u6236",//NO I18n
	"crm.zti.label.user": "\u7528\u6236\u540d", //NO I18n
	"crm.label.notSelected" : "\u672a\u9078\u4e2d",//NO I18n
	"AM" : "\u4e0a\u5348",//NO I18n
	"Call" : "\u96fb\u8a71",//NO I18n
	"crm.phoneNo.Link.Title" : "\u4f7f\u7528Skype\u64a5\u865f",//NO I18n
	"crm.button.cancel" : "\u53d6\u6d88",//NO I18n
	"crm.button.save" : "\u4fdd\u5b58",//NO I18n
	"crm.no.data.found" : "\u7121\u67e5\u8a62\u7d50\u679c",//NO I18n
	"crm.label.no.options.found" : "\u672a\u627e\u5230\u9078\u9805",//No I18n
	"crm.globalsearch.search.title" : "\u641c\u5c0b",//No I18n
	"None" : "\u7121",//No I18n
	"crm.label.criteria.pattern" : "\u7b26\u5408\u689d\u4ef6",//No I18n
	"crm.label.edit.criteria.pattern" : "\u7de8\u8f2f\u6a21\u5f0f",//No I18n
	"criteria.error.alert.brackets.count.mismatch" : "\u6a23\u5f0f\u7b26\u865f\u4e0d\u5339\u914d",//No I18n
	"criteria.error.alert.brackets.invalid" : "\u689d\u4ef6\u904b\u7b97\u5b50\u4e2d\u542b\u6709\u7121\u6548\u7684\u62ec\u5f27\u3002",//No I18n
	"crm.criteria.number.notmatch.check" : "\u8acb\u5728{0}\u4e2d\u6aa2\u67e5\u689d\u4ef6",//No I18n
	"criteria.error.alert.other.params" : "\u6b64\u6a23\u5f0f\u4e2d\u5305\u542b\u4e0d\u53ef\u7528\u5167\u5bb9", //No I18n
	"crm.label.search.for.users": "\u641c\u7d22\u7528\u6236", //NO I18n
	"criteria.error.alert.andor.rowcount.mismatch" : "\u6b64\u6a23\u5f0f\u4e2d\u7684\u689d\u4ef6\u6578\u8207\u689d\u4ef6\u7de8\u8f2f\u5668\u4e2d\u7684\u689d\u4ef6\u6578\u4e0d\u5339\u914d", //No I18n
	"criteria.error.alert.critnum.rowcount.mismatch" : "\u6b64\u6a23\u5f0f\u4e2d\u7684\u689d\u4ef6\u6578\u8207\u4f7f\u7528\u7684\u689d\u4ef6\u7de8\u8f2f\u5668\u4e2d\u7684\u689d\u4ef6\u6578\u4e0d\u5339\u914d", //No I18n
	"and" : "\u548c", //No I18n
	"or" : "\u6216\u8005", //No I18n
	"crm.label.or" : "\u6216", //No I18n
	"crm.label.and" : "\u8207", //No I18n
	"crm.criteria.fieldlabel.valid.check" : "\u8acb\u5728\u884c{0}\u4e2d\u8f38\u5165\u53ef\u7528\u7684\u6b04\u4f4d\u6a19\u7c64", //No I18n
	"crm.criteria.condition.valid.check" : "\u8acb\u8f38\u5165\u8cc7\u6599\u6b04\u4f4d{0}\u7684\u53ef\u7528\u689d\u4ef6", //No I18n
	"crm.field.valid.check" : "\u8acb\u8f38\u5165\u6709\u6548\u7684{0}", //No I18n
	"crm.alert.label.savepattern" : "\u8acb\u5728\u4fee\u6539\u689d\u4ef6\u4e4b\u524d\u4fdd\u5b58\u6a23\u5f0f",//No I18n
	"is" : "\u662f",//No I18n
	"isn\'t" : "\u4e0d\u662f",//No I18n
	"contains" : "\u5305\u542b",//No I18n
	"doesn\'t contain" : "\u4e0d\u5305\u542b",//No I18n
	"starts with" : "\u958b\u59cb\u5b57\u5143\u7b26\u865f",//No I18n
	"ends with" : "\u7d50\u675f\u5b57\u5143\u7b26\u865f",//No I18n
	"is empty" : "\u70ba\u7a7a",//No I18n
	"is not empty" : "\u4e0d\u70ba\u7a7a",//No I18n
	"is before" : "\u4e4b\u524d",//No I18n
	"is after" : "\u4e4b\u5f8c",//No I18n
	"between" : "\u4ecb\u65bc",//No I18n
	"not between" : "\u4e0d\u4ecb\u65bc",//No I18n
	"Today" : "\u4eca\u5929",//No I18n
	"Tommorow" : "\u660e\u5929",//No I18n
	"Tommorow Onwards" : "\u660e\u5929\u4e4b\u5f8c",//No I18n
	"Yesterday" : "\u6628\u5929",//No I18n
	"Till Yesterday" : "\u622a\u81f3\u6628\u5929",//No I18n
	"Last Month" : "\u4e0a\u6708",//No I18n
	"Current Month" : "\u672c\u6708", //No I18n
	"Next Month" : "\u4e0b\u6708", //No I18n
	"Last Week" : "\u4e0a\u5468", //No I18n
	"Current Week" : "\u672c\u5468", //No I18n
	"Next Week" : "\u4e0b\u4e00\u5468", //No I18n
	"Age in Days" : "\u6301\u7e8c\u5929\u6578", //No I18n
	"Due in Days" : "\u5e7e\u5929\u5f8c\u5230\u671f", //No I18n
	"Scheduled" : "\u5df2\u5b89\u6392", //No I18n
	"Attended Dialled" : "\u63a5\u807d\u7684\u4f86\u96fb", //No I18n
	"Unattended Dialled" : "\u672a\u63a5\u807d\u7684\u4f86\u96fb", //No I18n
	"Overdue" : "\u903e\u671f", //No I18n
	"Cancelled" : "\u53d6\u6d88", //No I18n
	"Received" : "\u5df2\u6536\u5230", //No I18n
	"Missed" : "\u932f\u904e\u7684", //No I18n
	"crm.alert.character.not.allowed" : "\u4e0d\u652f\u6301{0}", //No I18n
	"crm.condition.in.last" : "\u5728\u6700\u8fd1", //No I18n
	"crm.zinvoice.dueIn" : "\u622a\u6b62\u65bc", //No I18n
	"on" : "\u5728",//No I18n
	"before" : "\u4e4b\u524d",//No I18n
	"crm.label.general.small.after" : "after",//No I18n
	"crm.thisweek" : "\u672c\u5468",//No I18n
	"crm.label.this.month" : "\u672c\u6708",//No I18n
	"crm.thisyear" : "\u4eca\u5e74",//No I18n
	"crm.source.user.and.system" : "User & System",//No I18n
	"crm.source.user.or.system" : "User Or System",//No I18n
	"crm.label.system2" : "\u7cfb\u7d71",//No I18n
	"crm.source.user.only" : "Only by User",//No I18n
	"crm.source.system.only" : "Only by System",//No I18n
	"crm.condition.till.today" : "\u5230\u4eca\u5929\u70ba\u6b62",//No I18n
	"game.month.left" : "1\u500b\u6708",//No I18n
	"game.months.left" : "{0}\u500b\u6708",//No I18n
	"crm.condition.last.30.days" : "in the last 30 day",//No I18n
	"crm.condition.last.60.days" : "in the last 60 day",//No I18n
	"crm.condition.last.90.days" : "in the last 90 day",//No I18n
	"crm.label.filter.typehere" : "\u5728\u9019\u88e1\u8f38\u5165", //No I18N
	"crm.filter.is.not" : "\u4e0d\u662f", //No I18n
	"crm.condition.until.now" : "Until Now",//No I18n
	"crm.filter.email.isblocked" : "is blocked",//No I18n
	"crm.filter.email.isnotblocked" : "is not blocked",//No I18n
	"crm.label.no.results.match" : "\u6c92\u6709\u5339\u914d\u7d50\u679c",//No I18n
	"crm.label.select.user" : "\u9ede\u64ca\u9078\u64c7\u7528\u6236", //No I18n
	"current.logged.in.user": "\u767b\u9304\u7528\u6236", //NO I18n
	"crm.setup.system.ziasimilarity" : "\u76f8\u4f3c\u6027\u63a8\u85a6", //NO I18n
	"zia.similarity.smartfilter.score":"\u76f8\u4f3c\u6027\u5f97\u5206",//no i18n
	"zia.similarity.smartfilter.records":"\u985e\u4f3c\u7684{0} -",//no i18n
	"zia.similarity.smartfilter.records.search":"\u986f\u793a\u76f8\u4f3c\u7684{0} -",//no i18n
	"crux.smartfilter.multiselect.maximum.selected":"\u60a8\u6700\u591a\u53ef\u4ee5\u9078\u64c7{0}\u500b{1}",//no i18n
	"crm.gdpr.notavailable.field" : "\u4e0d\u53ef\u7528", //NO I18n
	"current.logged.in.user.definition": "\u767c\u8d77\u8a18\u9304\u64cd\u4f5c\u7684\u4f7f\u7528\u8005\u3002", //NO i18n
	"crm.security.group": "\u7d44", //NO I18n
	"crm.security.role": "\u8077\u4f4d", //NO I18n
	"Date" : "\u65e5\u671f",//No I18n
	"crm.field.valid.decimal.check2" : "<i>{0} </i>\u6b04\u4f4d\u7684\u5c0f\u6578\u4f4d\u6578\u61c9\u8a72\u5c0f\u65bc\u6216\u7b49\u65bc{1}\u4f4d\u3002",//No I18n
	"crm.field.empty.check" : "{0}\u4e0d\u80fd\u70ba\u7a7a",//No I18n
	"crm.label.add.note": "\u6dfb\u52a0\u5099\u8a3b", //NO I18n
	"crm.label.simply.by": "\u7531", //NO I18n
	"crm.general.addnote": "\u6dfb\u52a0\u5099\u8a3b", //NO I18n
	"crm.general.addtitle": "\u6dfb\u52a0\u6a19\u984c", //NO I18n
	"crm.label.attach.file": "\u6dfb\u52a0\u9644\u4ef6", //NO I18N
	"crm.button.mass.delete": "\u522a\u9664", //NO I18N
	"crm.warning.delete.record": '\u60a8\u78ba\u5b9a\u8981\u79fb\u52d5"{0}"\u5230\u56de\u6536\u7ad9\u55ce\uff1f', //NO I18N
	"crm.label.yes": "\u662f", //NO I18N
	"crm.note.view.previous": "\u67e5\u770b\u4e4b\u524d\u7684\u5099\u8a3b", //NO I18N
  "of": "-", //NO I18N
	"crm.label.notes": "\u5099\u8a3b", //NO I18N
	"crm.note.recent.first": "\u964d\u51aa", //NO I18N
	"crm.note.recent.last": "\u6607\u51aa", //NO I18N
	"crm.territory.label.only": "\u50c5{0}", //no i18n
	"crm.select" : "\u9078\u64c7", //No I18n
	"crm.button.apply.filter" : "\u61c9\u7528\u7be9\u6aa2\u7a0b\u5f0f",//No I18n
	"crm.alert.maximum.text.values.contains" : "\u60a8\u4e0d\u80fd\u70ba\u6b64\u6b04\u4f4d\u8f38\u5165\u8d85\u904e{0}\u503c\u3002",//No I18n
	"PM" : "\u4e0b\u5348",//No I18n
	//start-cx create form keys
	"crm.duplicate.value.not.allowed": "\u4e0d\u5141\u8a31\u91cd\u8907\u503c\u3002",//no i18n
	"crm.duplicate.value.available": "\u767c\u73fe\u4e86\u542b\u6709\u76f8\u540c{1}\u7684\u5df2\u6709{0}\u3002",//no i18n
	"crm.duplicate.value.available.multiple": "\u767c\u73fe\u4e86\u591a\u500b\u542b\u6709\u76f8\u540c{1}\u7684{0}",//no i18n
	"crm.custombutton.nobuttons.found": "\u6c92\u6709\u627e\u5230\u6309\u9215",//no i18n
	"crm.custombutton.create.newbutton": "\u5275\u5efa\u6309\u9215",//no i18n
	"crm.custombutton.manage.button": "\u7ba1\u7406\u6309\u9215",//no i18n
	"crm.custombutton.name": "\u540d\u7a31",//no i18n
	"crm.customize.custombutton.function.desc": "\u63cf\u8ff0",//no i18n
	"crm.custombutton.action": "\u6309\u9215\u64cd\u4f5c",//no i18n
	"crm.custombutton.empty.field.values": "\u767c\u73fe\u4e86\u4ee5\u4e0b\u6b04\u4f4d\u7684\u503c\u70ba\u7a7a:",//no i18n
	"crm.custombutton.proceed.action": "\u60a8\u78ba\u5b9a\u4ecd\u60f3\u7e7c\u7e8c\u55ce\uff1f",//no i18n
	"zb.common.Cancel": "\u53d6\u6d88",//no i18n
	"crm.yes.proceed": "\u662f\u7684\uff0c\u7e7c\u7e8c",//no i18n
	"crm.label.module.merge": "\u5408\u4f75{0}",//no i18n
	"crm.view.record": "\u67e5\u770b{0}",//no i18n
	"crm.tpi.ctiapi.config.choosefile": "\u9078\u64c7\u6a94",//no i18n
	"crm.label.subform.addrows": "\u6dfb\u52a0\u884c",//no i18n
	"crm.button.save&new": "\u4fdd\u5b58\u4e26\u65b0\u5efa",//no i18n
	//end-cx create form keys
	"crm.mb.newversion.msg4" : "\u597d\u7684\uff0c\u77ad\u89e3",//No I18n
	"Jan" : "\u4e00\u6708",//No I18n
	"Feb" : "\u4e8c\u6708",//No I18n
	"Mar" : "\u4e09\u6708",//No I18n
	"Apr" : "\u56db\u6708",//No I18n
	"Jun" : "\u516d\u6708",//No I18n
	"Jul" : "\u4e03\u6708",//No I18n
	"Aug" : "\u516b\u6708",//No I18n
	"Sep" : "\u4e5d\u6708",//No I18n
	"Oct" : "\u5341\u6708",//No I18n
	"Nov" : "\u5341\u4e00\u6708",//No I18n
	"Dec" : "\u5341\u4e8c\u6708",//No I18n
	"crm.label.More" :"\u66f4\u591a", //no i18n
	"crm.label.unmapped.stages":"\u672a\u8aaa\u660e\u7684", //no i18n
	"crm.wf.summary.label.ShowInstantActions" : "\u986f\u793a\u66f4\u591a",//No I18n
	 "crm.wf.summary.label.HideInstantActions" : "\u986f\u793a\u66f4\u5c11",//No I18n
	 	 //filter related keys-start
	"crm.lead.prediction.convert.high":"\u9ad8",//no i18n
"crm.inv.mail.time.two.days.subject":"{0} - {1}",//no i18n
"crm.lead.prediction.convert.medium":"\u4e2d",//no i18n
"crm.lead.prediction.convert.low":"\u4f4e",//no i18n
"crm.lead.prediction.tooltip.convert":"\u8f49\u63db",//no i18n
"crm.lead.prediction.tooltip.score":"\u5f97\u5206",//no i18n
"crm.criteria.max.rowcnt.exceeds":"\u7121\u6cd5\u6dfb\u52a0\u66f4\u591a\u689d\u4ef6",//no i18n
"Planned":"\u8a08\u756b\u4e2d",//no i18n
"Invited":"\u5df2\u9080\u8acb",//no i18n
"Sent":"\u5df2\u767c\u9001",//no i18n
"Received":"\u5df2\u63a5\u6536",//no i18n
"Opened":"\u6253\u958b\u7684",//no i18n
"Responded":"\u5df2\u56de\u5fa9",//no i18n
"Bounced":"\u5df2\u62d2\u7d55",//no i18n
"Opted\ Out":"\u9078\u64c7\u9000\u51fa",//no i18n
"crm.filter.label.with.open":"\u4f7f\u7528\u6253\u958b{0}",//no i18n
"crm.filter.label.without.open":"\u6c92\u6709\u9032\u884c\u4e2d\u7684{0}",//no i18n
"crm.filter.label.without.any":"\u6c92\u6709\u4efb\u4f55{0}",//no i18n
"crm.filter.label.with.module":"\u4f7f\u7528{0}",//no i18n
"crm.filter.label.activity.due":"{0}\u5230\u671f",//no i18n
"crm.filter.label.activity.done":"{0}\u5b8c\u6210",//no i18n
"Notes":"\u5099\u8a3b",//no i18n
"crm.filter.label.notes.added":"\u6dfb\u52a0\u7684\u5099\u8a3b",//no i18n
"crm.label.filter.email.status":"\u6700\u65b0\u90f5\u4ef6\u72c0\u614b",//no i18n
"crm.label.filter.email.clicked":"\u5df2\u9ede\u64ca",//no i18n
"crm.label.filter.email.responded":"\u5df2\u56de\u590d",// no i18n
"crm.label.filter.email.info":"\u6839\u64da\u5df2\u767c\u9001/\u5df2\u63a5\u6536\u96fb\u5b50\u90f5\u4ef6\u7684\u6700\u65b0\u72c0\u614b\u904e\u6ffe\u8a18\u9304\u3002",//no i18n
"crm.filter.label.sent":"\u5df2\u767c\u9001",//no i18n
"crm.filter.label.not.sent":"\u672a\u767c\u9001",//no i18n
"crm.filter.label.opened":"\u5df2\u6253\u958b",//no i18n
"crm.filter.label.not.opened":"\u672a\u6253\u958b",//no i18n
"crm.filter.label.received":"\u5df2\u63a5\u6536",//no i18n
"crm.filter.label.not.received":"\u672a\u63a5\u6536",//no i18n
"crm.filter.label.bounced":"\u5df2\u9000\u4fe1",//no i18n
"crm.filter.label.opened.not.replied":"\u5df2\u6253\u958b\u4f46\u672a\u56de\u5fa9",// no i18n
"crm.filter.label.any":"\u4ee5\u4e0a\u4efb\u610f",//no i18n
"crm.zia.config.potential.amount":"{0}\u91d1\u984d",//no i18n
"Quote\ Stage":"{0}\u968e\u6bb5",//no i18n
"crm.module.owner":"{0}\u6240\u6709\u8005",//no i18n
"Potential\ Closing\ Date":"{0}\u9810\u8a08\u6210\u4ea4\u65e5\u671f",//no i18n
"crm.lead.prediction.likely.convert":"\u53ef\u80fd\u8f49\u63db",//no i18n
"crm.predictions.feature.label":"\u9810\u6e2c",//no i18n
"crm.intelligence.prediction.likelywin":"\u53ef\u80fd\u8d0f\u5f97",//no i18n
"crm.intelligence.prediction.likelylose":"\u53ef\u80fd\u5931\u53bb",//no i18n
"crm.intelligence.prediction.halfchance":"\u4e00\u534a\u7684\u6a5f\u6703",//no i18n
"crm.intelligence.prediction.score":"\u9810\u6e2c\u7a4d\u5206",//no i18n
"crm.lead.prediction.recent.score":"\u6700\u8fd1\u7684\u9810\u6e2c\u5206\u6578",//no i18n
"crm.intelligence.prediction.lastconv":"\u6700\u5f8c3\u6b21\u5c0d\u8a71",//no i18n
"crm.intelligence.prediction.recordsfocus":"\u91cd\u9ede\u8a18\u9304",//no i18n
"crm.intelligence.prediction.slowmoving":"\u7de9\u6162\u79fb\u52d5",//no i18n
"crm.intelligence.prediction.trend.down":"\u6700\u8fd1\u8da8\u52e2\u4e0b\u964d",//no i18n
"crm.label.touched.records":"\u53d7\u5f71\u97ff\u7684\u8a18\u9304",//no i18n
"crm.label.untouched.records":"\u672a\u53d7\u5f71\u97ff\u7684\u8a18\u9304",//no i18n
"crm.label.record.action":"\u8a18\u9304\u64cd\u4f5c",//no i18n
"workflow.rule.view.label.Modified":"\u5df2\u4fee\u6539",//no i18n
"crm.label.not.modified":"\u672a\u4fee\u6539",//no i18n
"crm.label.related.records.action":"\u76f8\u95dc\u7684\u64cd\u4f5c\u8a18\u9304",//no i18n
"Done":"\u5b8c\u6210",//no i18n
"crm.label.not.done":"\u5c1a\u672a\u5b8c\u6210",//no i18n
"sentiment.model":"\u96fb\u5b50\u90f5\u4ef6\u89c0\u9ede\u6a21\u578b",//no i18n
"sentiment.criteria.count":"\u6578",//no i18n
"sentiment.criteria.percentage":"\u767e\u5206\u6bd4",//no i18n
"sentiment.criteria.lastmail":"\u6700\u5f8c\u4e00\u5c01\u90f5\u4ef6",//no i18n
"Chats":"\u804a\u5929",//no i18n
"Attended":"\u53c3\u8207\u4e86",//no i18n
"crm.lead.prediction.popup.text":"\u7576\u53ef\u80fd\u8f49\u63db",//no i18n
"crm.lead.prediction.popup.final":"\u8acb\u76f8\u61c9\u5730\u66f4\u6539\u7be9\u6aa2\u7a0b\u5f0f\uff0c\u7136\u5f8c\u91cd\u8a66\u3002",//no i18n
"crm.custom.field.less.than.to1":"\u7bc4\u570d\u7684\u8d77\u59cb\u503c\u4e0d\u80fd\u5927\u65bc\u7d50\u675f\u503c\u3002",//no i18n
"Last\ Activity\ Date":"\u6700\u8fd1\u64cd\u4f5c\u65e5\u671f",//no i18n
"crm.label.vendor.name":"{0}\u540d\u7a31",//no i18n
"hours":"\u5c0f\u6642",//no i18n
"days":"\u5929",//no i18n
"weeks":"\u5468",//no i18n
"months":"\u6708",//no i18n
"years":"\u5e74",//no i18n
"crm.label.general.small.after":"\u4e4b\u5f8c",//no i18n
"Last\ Week":"\u4e0a\u5468",//no i18n
"Last\ Month":"\u4e0a\u6708",//no i18n
"crm.module.name":"{0}\u540d\u7a31",//no i18n
"Campaign":"\u5e02\u5834\u6d3b\u52d5",//no i18n
"Tasks":"\u4efb\u52d9",//no i18n
"Calls":"\u901a\u8a71",//no i18n
"Events":"\u4e8b\u4ef6/\u6d3b\u52d5",//no i18n
"sentiment.criteria.wrongcriteria":"\u689d\u4ef6\u503c\u4e0d\u80fd\u8d85\u904e{0}",//no i18n
"crm.chosen.minimum.input.text":"\u8acb\u8f38\u5165{0}\u500b\u6216\u66f4\u591a\u7684\u5b57\u5143",//no i18n
"crm.intelligence.prediction.trendup":"\u4e0a\u5347\u8da8\u52e2",//no i18n
"crm.intelligence.prediction.trenddown":"\u4e0b\u964d\u8da8\u52e2",//no i18n
"crm.zia.prediction.notrend":"No trend",//no i18n
"crm.zia.prediction.likelihood":"Likelihood Score",//no i18n
"Completed":"\u5b8c\u6210" ,//no i18n
"crm.label.success":"\u6210\u529f" ,//no i18n
"crm.label.Failure":"\u5931\u6557" ,//no i18n
"Both":"\u5169\u8005\u540c\u6642" ,//no i18n
"crm.condition.cannot.empty":"\u689d\u4ef6\u4e0d\u80fd\u70ba\u7a7a\u3002",//no i18n
"crm.condition.last.30.days":"\u5728\u904e\u53bb30\u5929",//no i18n
"crm.condition.last.60.days":"\u5728\u904e\u53bb60\u5929",//no i18n
"crm.condition.last.90.days":"\u5728\u904e\u53bb90\u5929",//no i18n
"crm.sentiment.Positive":"\u7a4d\u6975",//no i18n
"crm.sentiment.Negative":"\u6d88\u6975",//no i18n
"sentiment.positiveandnegative":"\u6b63\u6578\u548c\u8ca0\u6578",//no i18n
"sentiment.positiveornegative":"\u6b63\u6578\u6216\u8ca0\u6578",//no i18n
"sentiment.positiveonly":"\u50c5\u6b63\u6578",//no i18n
"sentiment.negativeonly":"\u50c5\u8ca0\u6578",//no i18n
"crm.sentiment.Neutral":"\u4e2d\u6027",//no i18n
"crm.filters.select.campaign.type":"\u9078\u64c7{0}\u7684\u985e\u578b",//no i18n
"crm.filters.select.campaign.status":"\u9078\u64c7{0}\u7684\u72c0\u614b",//no i18n
"campaign.Member" : "\u6210\u54e1",//no i18n
	"Service":"\u670d\u52d9",//no i18n
"Activities":"\u4efb\u52d9\u3001\u901a\u8a71\u53ca\u6d3b\u52d5",//no i18n
"crm.livedesk.pot.nextdays":"\u672a\u4f86{0}\u5929",//no i18n
"Today\ +\ Overdue":"\u4eca\u65e5+\u904e\u671f",//no i18n
"crm.source.user.and.system":"\u4f7f\u7528\u8005\u548c\u7cfb\u7d71",//no i18n
"crm.source.user.or.system":"\u4f7f\u7528\u8005\u6216\u7cfb\u7d71",//no i18n
"User":"\u7528\u6236",//no i18n
"crm.source.user.only":"\u50c5\u7528\u6236",//no i18n
"crm.source.system.only":"\u50c5\u7cfb\u7d71",//no i18n
"Scheduled":"\u5df2\u5b89\u6392",//no i18n
"Attended\ Dialled":"\u63a5\u807d\u7684\u4f86\u96fb",//no i18n
"Unattended\ Dialled":"\u672a\u63a5\u807d\u7684\u4f86\u96fb",//no i18n
"Cancelled":"\u53d6\u6d88",//no i18n
"crm.filter.email.isblocked":"\u88ab\u9396\u5b9a",//no i18n
"crm.filter.email.isnotblocked":"\u672a\u88ab\u9396\u5b9a",//no i18n
"condition.till.now":"\u76f4\u5230\u73fe\u5728",//no i18n
"crm.recurring.no.months":"{0}\u6708",//no i18n
"crm.lead.prediction.tooltip":"\u53ef\u80fd\u8f49\u63db - \u5206\u6578\u7bc4\u570d",//no i18n
"crm.website.activity":"\u7db2\u7ad9\u6d3b\u52d5",//no i18n
"crm.label.By":"\u901a\u904e",//no i18n
"crm.chosen.searching.text":"\u641c\u7d22\u4e2d\u2026",//no i18n
"crm.label.memberstatus.is":"\u548c\u6210\u54e1\u72c0\u614b\u662f",//no i18n
"crm.events.duration":"\u6301\u7e8c\u6642\u9593",//no i18n
"crm.title.clear.name":"\u6e05\u7a7a",//no i18n
"crm.label.status.is":"\u4e26\u4e14\u72c0\u614b\u662f",//no i18n
"zia.last3.help":"\u6703\u8a71\u5305\u62ec\u901a\u8a71\u3001\u4efb\u52d9\u3001{0}\u3001\u63a5\u6536\u7684\u90f5\u4ef6\u3001\u5099\u8a3b\u3001\u8a2a\u554f\u3001\u793e\u4ea4\u8a55\u8ad6\u3001\u4f86\u81eaDesk\u7684\u652f\u6301\u8acb\u6c42\u3002",//no i18n
"crm.label.tag.related.to":"\u6709\u95dc",//no i18n

	//filter related keys-End
	"crm.label.account.created" : "\u65b0\u7684{0}\u5c07\u88ab\u5275\u5efa\u3002",//No I18n
	"crm.krp.no.records.found" : "\u6c92\u6709\u627e\u5230{0}\u3002",//No I18n
	"crm.module.new" : "\u65b0\u5efa{0}",//No I18n
	"crm.label.view" : "\u67e5\u770b",//No I18n
	"crm.nsocial.customers" : "\u5ba2\u6236",//No I18n
	"crm.nsocial.open.potential" : "\u6253\u958b{0}",//No I18n
	"crm.nsocial.lead.contact" : "{0}/{1}",//No i18n
	"Others" : "\u5176\u5b83",//No i18n
	"crm.field.length.check" : "{0}\u503c\u8d85\u904e\u6700\u5927\u9577\u5ea6", //No I18n
	"crm.lower.now": "\u73fe\u5728",//no i18n
	"crm.time.min.ago": "{0}\u5206\u9418\u4e4b\u524d",//no i18n
	"crm.time.mins.ago":"{0}\u5206\u9418\u4e4b\u524d",//no i18n
	"crm.time.hr.ago": "{0}\u5c0f\u6642\u4e4b\u524d",//no i18n
	"crm.time.hrs.ago": "{0}\u5c0f\u6642\u4e4b\u524d", //no i18n
	"AllUsers": "\u6240\u6709\u7528\u6236" ,//no i18n
	"crm.label.search":"\u641c\u7d22",//no i18n
	"crm.api.filterby":"\u904e\u6ffe\u901a\u904e",//no i18n
	"crm.customview.nofields.found":"--\u6c92\u6709\u5339\u914d\u7684\u6b04\u4f4d--",//no i18n
	"crm.setup.system.ziarecommendation":"\u63a8\u85a6",//no i18n
	"crm.filter.label.all.products":"\u6240\u6709{0}",//no i18n
	"crm.filter.label.select.products":"\u9078\u64c7\u7684{0}",//no i18n
	"crm.reviewprocess.smart.filter":"\u5be9\u6838\u6d41\u7a0b\u7684\u8a18\u9304\u72c0\u614b",//no i18n
	"crm.dashboard.sharing.empty.value.alert":"\u8acb\u9078\u64c7\u4e00\u500b\u503c\u3002",//no i18n
	"crm.segmentation.segment.score":"\u7d30\u5206\u503c",//no i18n
	"crm.filter.label.in":"\u57fa\u65bc",//no i18n
	"crm.filter.label.and.purchase.in":"\u4e26\u4e14\u5f88\u53ef\u80fd\u8cfc\u8cb7",//no i18n
	"crm.filter.label.last.purchased":"\u4e26\u4e14\u6700\u8fd1\u8cb7\u904e",//no i18n
	"crm.filter.label.a.day":"\u4e00\u5929",//no i18n
	"crm.filter.label.a.week":"\u4e00\u5468",//no i18n
	"crm.filter.label.a.month":"\u4e00\u500b\u6708",//no i18n
	"crm.cal.custom":"\u81ea\u8a02",//no i18n
	"crm.mb.field.common.empt":"\u503c\u4e0d\u80fd\u70ba\u7a7a\u3002",//no i18n
	"crm.chosen.error.loading.text":"\u8f09\u5165\u7d50\u679c\u6642\u51fa\u932f",//no i18n
	"crm.filter.label.firstbuy":"\u9996\u6b21\u7684\u6642\u9593",//no i18n
	"crm.filter.label.cwbab":"\u4f9d\u8cf4",//no i18n
	"crm.filter.label.fbt":"\u6346\u7d81",//no i18n
	"crm.filter.label.rebuy":"\u91cd\u8907",//no i18n
	"crm.filter.label.nextbuy":"\u5e8f\u5217",//no i18n
	"crm.mxnlookup.select" : "\u5206\u914d{0}",//No I18n
	"crm.lookup.chooserecord":"\u9078\u64c7{0}",//no i18n
	"crm.record.selected":"\u9078\u4e2d\u7684{0}",//no i18n
	"crm.module.empty.message" : "\u627e\u4e0d\u5230{0}",//No I18n
	"crm.mxnlookup.selected" : "\u5df2\u9078\u64c7\u7684{0}",//No I18n
	"crm.label.creator.noPermission" : "\u7121\u6b0a\u8a2a\u554f\u3002", //No I18n
	"crm.security.error" : "\u6c92\u6709\u5145\u8db3\u7684\u8a31\u53ef\u6b0a\u4f86\u57f7\u884c\u8a72\u64cd\u4f5c\uff0c\u8acb\u548c\u7ba1\u7406\u54e1\u806f\u7e6b\u3002", //no i18n
	"crm.segmentation.recency" : "\u65b0\u8fd1\u6d88\u8cbb", //No I18n
	"crm.segmentation.frequency" : "\u6d88\u8cbb\u983b\u7387", //No I18n
	"crm.segmentation.monetary" : "\u6d88\u8cbb\u91d1\u984d", //No I18n
	"crm.smartfilter.related.module.msg" : "\u60a8\u4e0d\u80fd\u9078\u64c7\u4e09\u500b\u4ee5\u4e0a\u76f8\u95dc\u7684\u6a21\u7d44\u3002" , //No I18n
	"crm.smartfilter.related.module.msg1" : "\uff08\u4f8b\u5982\uff1a\u90f5\u7bb1\uff0c\u4efb\u52d9\u3001\u901a\u8a71\u53ca\u6d3b\u52d5\uff0c\u5099\u8a3b\uff09", //No I18n
	"crm.smartfilter.related.module.msg2" : "\u6301\u7e8c\u6642\u9593\u4e0d\u80fd\u70ba\u7a7a", //No I18n
	"crm.label.timeZone": "\u6642\u5340", //NO I18n
	"crm.label.insufficient.privileges": "\u6b0a\u9650\u4e0d\u5920\uff0c\u4e0d\u80fd\u57f7\u884c\u8a72\u64cd\u4f5c\uff0c\u8acb\u806f\u7e6b\u60a8\u7684\u7ba1\u7406\u54e1\u3002", //NO I18n
	"crm.filter.header.secton.system": "\u7cfb\u7d71\u5b9a\u7fa9\u7684\u904e\u6ffe\u5668", //NO I18N
	"crm.filter.header.secton.fields": "\u6309\u6b04\u4f4d\u904e\u6ffe", //NO I18N
	"crm.createfield.calcinfo.new" : "\u6b64\u6b04\u4f4d\u5145\u7576\u4e00\u500b\u8a08\u7b97\u5668\uff0c\u5c0d\u60a8\u8f38\u5165\u7684\u904b\u7b97\u5f0f\u9032\u884c\u8a08\u7b97\u3002</br> <b>\u5982\uff1a20+20</b>\u5c07\u81ea\u52d5\u8f49\u63db\u70ba<b>40</b>",//No i18n
	"crm.lable.read.only" : "\u552f\u8b80\u6b04\u4f4d",//No i18n
	"crm.column.sort.asc" : "\u6607\u51aa",//No I18n
	"crm.column.sort.desc" : "\u964d\u51aa",//No i18n
	"crm.column.unsort" : "\u53d6\u6d88\u6392\u5e8f",//No I18n
	"custmr.prtl.notes.shr.to.custmr": "\u5171\u7528\u7d66\u5ba2\u6236", //NO I18N
	"crm.label.edited": "\u5df2\u7de8\u8f2f", //NO I18N
	"crm.label.edited.on": "\u7de8\u8f2f\u65bc", //NO I18N
	"crm.message.limit.exceed": "{1}\u4e2d\u53ea\u80fd\u5305\u542b{0}\u500b\u5b57\u5143\u3002", //NO I18N
	"custmr.prtl.notes.shrd.with.custmr": "\u8207\u5ba2\u6236\u5171\u7528", //NO I18N
	"crm.button.ok" : "\u78ba\u5b9a", //NO I18N
	"crm.role.already.selected" : "\u6b64\u89d2\u8272\u5df2\u9078\u4e2d", //no i18n
	"crm.user.deleted": "\u4f7f\u7528\u8005\u5df2\u88ab\u522a\u9664",  //NO I18N
	"crm.account.closed": "\u6b64\u5e33\u6236\u5df2\u88ab\u95dc\u9589",  //NO I18N
	"crm.start.chat": "\u958b\u59cb\u804a\u5929",  //NO I18N
	"crm.start.call": "\u958b\u59cb\u4e00\u500b\u901a\u8a71",  //NO I18N
	"crm.recipient.invalid.email" : "\u767c\u73fe\u7121\u6548\u7684\u96fb\u5b50\u90f5\u4ef6\u3002", //NO I18N
	"crm.recipient.add.recipient" : "\u65b0\u589e\u984d\u5916\u6536\u4ef6\u8005", //NO I18N
	"crm.start.video.call": "\u958b\u59cb\u4e00\u500b\u8996\u8a0a\u901a\u8a71",  //NO I18N //ignorei18n_start

	"crm.label.scoring.rules":"\u7a4d\u5206\u898f\u5247",
	"Score":"\u7a4d\u5206",
	"Positive Score":"\u6b63\u7a4d\u5206",
	"Negative Score":"\u8ca0\u7a4d\u5206",
	"Touch Point Score":"\u89f8\u9ede\u7a4d\u5206",
	"Positive Touch Point Score":"\u6b63\u89f8\u9ede\u7a4d\u5206",
	"Negative Touch Point Score":"\u8ca0\u89f8\u9ede\u7a4d\u5206",
	"crm.label.type.minutes": "\u5728\u9019\u88e1\u8f38\u5165\u5206\u9418\u6578", //NO I18N
	"is\ OPEN":"\u70ba\u201c\u6253\u958b\u201d",//no i18n
	"is\ WON":"\u70ba\u201c\u8d0f\u55ae\u95dc\u9589\u201d",//no i18n
	"is\ LOST":"\u70ba\u201c\u4e1f\u55ae\u95dc\u9589\u201d",//no i18n
	"crm.potential.all.open":"\u6240\u6709\u201c\u6253\u958b\u201d\u7684\u968e\u6bb5",//no i18n
	"crm.potential.all.won":"\u6240\u6709\u201c\u8d0f\u55ae\u95dc\u9589\u201d\u7684\u968e\u6bb5",//no i18n

	"crm.potential.all.lost":"\u6240\u6709\u201c\u4e1f\u55ae\u95dc\u9589\u201d\u7684\u968e\u6bb5",//no i18n
	"crm.campaign.member.status" : "\u522a\u9664\u6b64\u6703\u54e1\u72c0\u614b\u7121\u6cd5\u64a4\u92b7\u3002",//no i18n
	"crm.dashboard.select.type" : "\u9078\u64c7{0}",//no i18n
	"crm.campaign.service.status":"\u670d\u52d9\u72c0\u614b",//no i18n

	"crm.label.addColumn":"\u6dfb\u52a0\u5217",//no i18n
	"crm.button.clear.filter":"\u95dc\u9589\u904e\u6ffe\u5668",//no i18n
	"crm.button.show.filter":"\u986f\u793a\u904e\u6ffe\u5668",//no i18n
	"crm.las.error.user.maxlimit":"\u60a8\u6700\u591a\u53ea\u80fd\u9078\u64c720\u500b\u7528\u6236\u3002",//no i18n
	"crm.las.error.picklist.maxlimit":"\u60a8\u6700\u591a\u53ea\u80fd\u9078\u64c720\u500b\u9078\u9805\u3002",//no i18n

	"crm.fileuploader.message.responseerror": "\u4e0a\u8f09\u5931\u6557", //NO I18N
	"crm.storage.create.error":"\u60a8\u5df2\u9054\u5230\u6700\u5927\u6578\u64da\u5b58\u5132\u9650\u5236\uff0c\u56e0\u6b64\u7121\u6cd5\u5275\u5efa\u65b0\u8a18\u9304\u3002",//no i18n
	"crm.storage.create.error.client":"\u7531\u65bc\u60a8\u7684\u7ba1\u7406\u54e1\u5df2\u9054\u5230\u5176\u6700\u5927\u5b58\u5132\u9650\u5236\uff0c\u56e0\u6b64\u7121\u6cd5\u5275\u5efa\u4efb\u4f55\u65b0\u8a18\u9304\u3002\u8acb\u806f\u7d61 {0} \u4f86\u89e3\u6c7a\u9019\u500b\u554f\u984c\u3002",//no i18n
	"crm.storage.avail.info":"(\u5269\u9918\uff1a{0}\uff0c\u7e3d\u8a08\uff1a{1})",//no i18n
	"crm.storage.error.key.manage":"\u7ba1\u7406\u60a8\u7684\u6578\u64da\u5b58\u5132",//no i18n
	"Records":"\u8a18\u9304" ,//no i18n
	"crm.workflow.alert.additional.recipients" : "\u5176\u4ed6\u6536\u4ef6\u4eba", //NO I18N
	"crm.workflow.alert.type.otherEmails" : "\u591a\u500b\u90f5\u5bc4\u5730\u5740\u4e4b\u9593\u7528\u9017\u865f(,)\u9694\u958b", //NO I18N
	"Records":"\u8a18\u9304",//no i18n
	"crm.related.contact.account" : "\u95dc\u806f\u5230{1}\u7684{0}",//No I18n
	"crm.allcontact.show" : "\u5168\u90e8{0}",//No I18n
	"crm.button.mass.show" : "\u986f\u793a",//No I18n
	"crm.msg.custom.view.not.replied" : "\u6c92\u6709\u56de\u590d\u7684\u6d88\u606f", //NO I18N
	"crm.msg.custom.view.replied" : "\u56de\u590d\u7684\u6d88\u606f",//NO I18N
	"crm.workflow.select.recipients" : "\u9078\u64c7\u6536\u4ef6\u4eba", //NO I18N
	"crm.custom.module.no.profile.selected.alert1":"\u6c92\u6709\u9078\u4e2d\u89d2\u8272\uff0c\u60a8\u5fc5\u9808\u81f3\u5c11\u9078\u64c7\u4e00\u500b\u3002",//NO I18N
	"crm.auto.enrich.remove.default.profile" : "\u5f88\u62b1\u6b49\uff0c\u7121\u6cd5\u522a\u9664\u9ed8\u8a8d\u8a2d\u5b9a\u6a94\u3002",//NO I18N
	"crm.inv.label.add.emails" : "\u6dfb\u52a0\u90f5\u7bb1" ,//NO I18N
	"crm.prediction.analytics.filter.year":"\u53bb\u5e74",//no i18n
	"Previous\ FY":"\u4e0a\u4e00\u8ca1\u5e74",//no i18n
	"Current\ FY":"\u7576\u524d\u8ca1\u5e74",//no i18n
	"Next\ FY":"\u4e0b\u4e00\u8ca1\u5e74",//no i18n
	"Current\ FQ":"\u7576\u524d\u8ca1\u5b63",//no i18n
	"Next\ FQ":"\u4e0b\u4e00\u8ca1\u5b63",//no i18n
	"Previous\ FQ":"\u4e0a\u4e00\u8ca1\u5b63",//no i18n
	"crm.label.next.year":"\u660e\u5e74",//no i18n
	"crm.inv.label.add.emails" : "\u6dfb\u52a0\u90f5\u7bb1", //NO I18N
	"crm.picklist.sample.text":"\u793a\u4f8b\u6587\u672c",//no i18n
	"crm.more.colors":"\u66f4\u591a\u984f\u8272",//no i18n
	"crm.label.simply.in":"\u5728",//no i18n
	"crm.button.back.alone":"\u8fd4\u56de",//no i18n
	"crm.field.label.email":"\u90f5\u7bb1",//no i18n
"crm.zia.nba.feature.label":"\u4e0b\u4e00\u6b65\u6700\u4f73\u884c\u52d5",//no i18n
"Meeting":"\u6703\u8b70",//no i18n
"Tomorrow":"\u660e\u5929",//NO I18N
'crm.zia.nbx.filter.due' : "Due", //NO I18N
"abm.segment": "\u5340\u6bb5", // NO I18N
	"abm.segment.names": "\u5340\u6bb5\u540d", // NO I18N
	"abm.abm.segment.name": "ABM \u5340\u6bb5\u540d", // NO I18N
	"abm.segmentation.techniques": "ABM \u5340\u6bb5\u6280\u8853", // NO I18N
	"abm.filters": "ABM \u7be9\u9078\u5668", // NO I18N
	"abm.filters.info": "\u9019\u4e9b\u7be9\u9078\u5668\u5c07\u57fa\u65bc\u8207 {1} \u95dc\u806f\u7684 {0} \u986f\u793a\u8cc7\u8a0a\u3002", // NO I18N
	"abm.rfm.label": "RFM \u6a19\u7c64", // NO I18N
	"abm.firmographics.label": "\u516c\u53f8\u4eba\u53e3\u7d71\u8a08\u6a19\u7c64" ,// NO I18N
	"abm.recommendation.label": "\u5efa\u8b70\u6a19\u7c64", // NO I18N
	"abm.voc.label": "VOC \u6a19\u7c64", // NO I18N
	"abm.engagement.label": "\u4e92\u52d5\u6a19\u7c64", // NO I18N
	"abm.overall.score": "\u7e3d\u5206", // NO I18N
	"abm.mood.score": "\u60c5\u7dd2\u5206\u6578", // NO I18N
	"abm.value.score": "\u50f9\u503c\u5206\u6578", // NO I18N
	"abm.engagement.score": "\u4e92\u52d5\u5206\u6578", // NO I18N
	"abm.rfm.score": "RFM \u5206\u6578", // NO I18N
	"crm.column.list.max.group.limit" : "", //no i18n
	"crm.customview.pin.column":"\u56fa\u5b9a\u5217",//no i18n
"crm.customview.unpin.column":"\u53d6\u6d88\u56fa\u5b9a\u5217",//no i18n
	"crux.custom.field.greater.than.equalto":"{0}必須大於或等於{1}。",
	"crux.users.selected.plural" : "已選取 {0} 位使用者。",
	"crux.user.selected.singular" :"已選取 {0} 位使用者。",
	"crux.criteria.empty.secondaryfield.module" : "在 {1} 模組中找不到相符的 {0} 欄位",
	"crux.criteria.empty.secondaryfield" : "無其他 {0} 欄位可用於比較，請輸入一個值進行比較。",
	"crux.logged.in.role.definition" : "起始記錄動作的使用者角色",
	"zia.similarity.smartfilter.score":"\u76f8\u4f3c\u6027\u5f97\u5206",//no i18n
	"zia.similarity.smartfilter.records":"\u76f8\u4f3c\u7684{0}\uff1a",//no i18n
	"zia.similarity.smartfilter.records.search":"\u986f\u793a\u76f8\u4f3c\u7684{0}\uff1a",//no i18n
	"crux.smartfilter.multiselect.maximum.selected":"\u60a8\u6700\u591a\u53ef\u4ee5\u9078\u64c7{0}\u500b{1}",//no i18n
	"crm.competitoralert.mentioned.in.email":"\u5728<b>\u96fb\u5b50\u90f5\u4ef6</b>\u4e2d\u63d0\u53ca",
	"crm.competitoralert.with.sentiment":"\u5e36\u6709\u60c5\u611f",
	"crm.competitoralert.name.error":"\u8acb\u63d0\u4f9b\u7af6\u722d\u5c0d\u624b\u7684\u540d\u7a31\u3002",
	"crm.competitoralert.date.error":"\u8acb\u63d0\u4f9b\u5728\u90f5\u4ef6\u4e2d\u63d0\u53ca\u7af6\u722d\u5c0d\u624b\u7684\u65e5\u671f\u3002",
	"crm.competitoralert.sentiment.error":"\u8acb\u9078\u64c7\u4e00\u500b\u6709\u6548\u7684\u9078\u9805\u4f86\u8868\u9054\u7af6\u722d\u5c0d\u624b\u7684\u60c5\u611f\u3002",
	"crm.competitor.name":"\u7af6\u722d\u5c0d\u624b",
	"abm.firmographics": "Firmographics" ,// NO I18N
	"crux.custom.field.greater.than.equalto":"{0}必須大於或等於{1}。",
	"crux.users.selected.plural" : "已選取 {0} 位使用者。",
	"crux.user.selected.singular" :"已選取 {0} 位使用者。",
	"crux.criteria.empty.secondaryfield.module" : "在 {1} 模組中找不到相符的 {0} 欄位",
	"crux.criteria.empty.secondaryfield" : "無其他 {0} 欄位可用於比較，請輸入一個值進行比較。",
	"crux.logged.in.role.definition" : "起始記錄動作的使用者角色",
	"crux.max.limit.unselect" : "您不能取消選定超過 {0} {1}。", //NO I18N
	"crux.existing.tag" : "\u201c{0}\u201d 已選定", //NO I18N
	"crm.label.true" : "\u771f",//NO I18N
	"crm.label.false" : "\u5047",//NO I18N
	"crm.record.locked" : "\u5df2\u9396\u5b9a",//NO I18N
	"crm.condition.belongs.to" : "\u5c6c\u65bc{0}", //NO I18N
	"crm.condition.not.belongs.to" : "\u4e0d\u5c6c\u65bc{0}", //NO I18N
	"crm.filter.number.range":"\u8f38\u5165 {0} - {1} \u4e4b\u9593\u7684\u503c",//no i18n
	"crm.smartfilter.picklist.options.msg":"\u60a8\u7121\u6cd5\u70ba10\u500b\u4ee5\u4e0a\u7684\u9078\u5b9a\u503c\u9078\u64c7\u6b64\u9078\u9805\u3002",//no i18n
	"crm.chosen.maximum.campaigns.selected":"\u60a8\u4e0d\u80fd\u9078\u64c7\u8d85\u904e5\u500b\u4ee5\u4e0a\u7684{0}",//no i18n
	"crux.smartfilter.multiselect.maximum.selected":"\u60a8\u6700\u591a\u53ef\u4ee5\u9078\u64c7{0}\u500b{1}",//no i18n
	"crm.best.time.column.label":"\u6700\u4f73\u806f\u7d61\u6642\u9593",//no i18n
	"crm.email.unblock.filter.temporary":"\u66ab\u6642\u7684",
	"crm.email.unblock.filter.permanent":"\u6c38\u4e45",
	"crm.email.unblock.filter.both":"\u5169\u8005\u540c\u6642",
	"crm.email.unblock.filter.category":"\u985e\u5225",
"crm.lookup.advance.error.msg" : "\u5728\u61c9\u7528\u7be9\u9078\u5668\u6642\u51fa\u4e86\u9ede\u554f\u984c\u3002\u5237\u65b0\u9801\u9762\u4e26\u518d\u6b21\u5617\u8a66\u3002",
	"Contact\ Name" : "{0}\u540d\u7a31",
	"Lead\ Name" : "{0}\u540d\u7a31",
	"-None-" : "-\u7121-",
	"January" : "1\u6708",
	"February":"2\u6708",
	"March":"3\u6708",
	"April":"4\u6708",
	"May":"5\u6708",
	"June":"6\u6708",
	"July":"7\u6708",
	"August":"8\u6708",
	"September":"9\u6708",
	"October":"10\u6708",
	"November":"11\u6708",
	"December":"12\u6708",
	"crm.label.add.tags":"\u6dfb\u52a0\u6a19\u7c64",//no i18n
	"crm.confirm.deassociate":"\u53d6\u6d88\u5206\u914d",//no i18n
	// Image Upload Open
	"crm.label.browse.files":"\u6d41\u89bd\u6a94",//no i18n
	"crm.label.lowercase.or":"\u6216\u8005",//no i18n
	"crm.image.header":"\u5716\u7247",//no i18n
	"crm.image.n.n":"N:N \u6bd4\u7387",//no i18n
	"crm.image.2.2":"2:2 \u6bd4\u7387",//no i18n
	"crm.image.4.3":"4:3 \u6bd4\u7387",//no i18n
	"crm.image.16.9":"16:9 \u6bd4\u7387",//no i18n
	"crm.image.height.width.swap":"\u4ea4\u63db\u9ad8\u5ea6\u548c\u5bec\u5ea6",//no i18n
	"crm.image.rotate.image":"\u65cb\u8f49",//no i18n
	"crm.label.lowercase.of":"\u7684",//no i18n
	"crm.image.empty.name.placeholder" : "\u8f38\u5165\u4e00\u500b\u540d\u7a31",
	"crm.image.crop.and.rotate" : "\u88c1\u526a\u4e26\u65cb\u8f49",
	"crm.image.description" : "\u6dfb\u52a0\u63cf\u8ff0...",
	"crm.image.actual.size" : "\u5be6\u969b\u5c3a\u5bf8",
	"crm.image.reset" : "\u91cd\u7f6e",
	"crm.image.width.px" : "px",
	"crm.view.attachment.download":"\u4e0b\u8f09",//no i18n
	"crm.label.edit":"\u7de8\u8f2f",//no i18n
	"crm.label.close":"\u95dc\u9589",//no i18n
	"crm.label.any":"\u4efb\u610f",
	"crm.image.description":"\u6dfb\u52a0\u63cf\u8ff0...",//no i18n
	"crm.image.zoom.in":"\u653e\u5927",//no i18n
	"crm.image.zoom.out":"\u7e2e\u5c0f",//no i18n
	"crm.label.lowercase.of":"\u7684",//no i18n
	"crm.image.desc.maxsize":"\u63cf\u8ff0\u4e0d\u61c9\u8d85\u904e1000\u500b\u5b57\u7b26\u3002",//no i18n
	"crm.image.name.maxsize":"\u540d\u7a31\u4e0d\u61c9\u8d85\u904e100\u500b\u5b57\u7b26\u3002",//no i18n
	"crm.fileuploader.message.totalfilesizeexceeded":"\u6a94\u7e3d\u5927\u5c0f\u8d85\u904e\u4e86\u5141\u8a31\u7684\u9650\u5236{0}\u3002",//no i18n
	"crm.fileuploader.message.maxfilesexceeded":"\u60a8\u6700\u591a\u53ea\u80fd\u4e0a\u8f09{0}\u500b\u6587\u4ef6\u3002",//no i18n
	"crm.fileuploader.message.maxfilesexceeded1":"\u60a8\u6700\u591a\u53ea\u80fd\u9644\u52a0{0}\u500b\u6587\u4ef6\u3002",//no i18n
	"crm.ImageuploadField.size.limit":"\u4e0a\u8f09\u7684\u5716\u7247\u6587\u4ef6\u8d85\u904e\u4e86\u5141\u8a31\u7684{0} MB\u7684\u9650\u5236",//no i18n
	"crm.attachment.imageUploadField.Minlen.check":"\u60a8\u53ea\u80fd\u9078\u64c7 1 \u500b\u5716\u7247",//no i18n
	"zc.editor.width":"\u5bec\u5ea6",//no i18n
	"ze.editor.height":"\u9ad8\u5ea6",//no i18n
	"crm.label.delete":"\u522a\u9664",//no i18n
	"crm.image.error.gif":"GIF\u5716\u50cf\u7121\u6cd5\u88c1\u526a\u3002",//no i18n
	"crm.fileuploader.message.morefiles":"{0}\u5176\u5b83\u6587\u4ef6",//no i18n
	"crm.fileuploader.message.invalidfileType1":"\u6a94{0}\u4e0d\u662f\u652f\u6301\u7684\u9644\u4ef6\u683c\u5f0f\u3002",//no i18n
	"crm.fileuploader.message.invalidfileType2":"\u6a94{0}\u548c{1}\u90fd\u4e0d\u652f\u6301\u70ba\u9644\u4ef6\u3002",//no i18n
	"crm.image.crop.error":"\u7121\u6cd5\u88c1\u526a\u5716\u7247\uff0c\u8acb\u518d\u8a66\u4e00\u6b21\uff01",//no i18n
	
	
	
	"crm.fileuploader.message.maxfilesexceeded" : "\u60a8\u6700\u591a\u53ea\u80fd\u4e0a\u8f09{0}\u500b\u6587\u4ef6\u3002",
"crm.file.upload.maxlength.exceeds" : "\u5728{0}\u4e2d\u53ea\u80fd\u4e0a\u8f09\u4e00\u500b\u6a94\u3002"


}
